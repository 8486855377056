import PdfViewer from '../PdfViewer'
import pdf from './resume.pdf'
import React, {useState} from 'react';
import Typical from 'react-typical'
import aii from '../../assets/aii.png';
import './header.css';
import { SocialIcon } from 'react-social-icons';
import '../../containers/pdfviewer.css'

function Header() {
  const [showPdf, setShowPdf] = useState(false)
  return (
  <div className="gpt3__header section__padding" id="home" >
    <div className="gpt3__header-content">
      <h1 className="gradient__text">Welcome to My Website Guys...</h1>
      <p>Hello Guys I'm a...'website Developer','Data science Enthusiast','Bakend Developer'</p>
      <div className="gpt3__header-content__people">  
      <PdfViewer className="pdfApp" pdf={pdf} onCancel={()=>setShowPdf(false)} visible={showPdf} />
      <button onClick={()=>setShowPdf(!showPdf)} className="btn-submit-requests" type="submit">Resume</button>
      <button class="btn-reset" type="reste">Contact</button> <br/>        
      </div>
      <div className="social_icons">
      <SocialIcon url="https://linkedin.com/in/jaketrent" bgColor="rgb(0, 172, 237)"/>
      <SocialIcon url="www.facebook.com" network="facebook" bgColor="rgb(191, 126, 178)"/>
      <SocialIcon url="www.stackoverflow.com" network="stackoverflow" />
      <SocialIcon url="www.github.com" network="github" bgColor="rgb(168, 183, 198)"/>
      <SocialIcon url="www.twitter.com" network="twitter" bgColor="rgb(147, 144, 183)"/>
      <SocialIcon url="www.reddit.com" network="reddit" bgColor="#cb3837"/>
      </div>  
    </div>
    <div>
     
      <section>

        {/* <div className="container"> */}
        <div>
          <div className="background-img">
              {/* <img src={background} alt="background" /> */}
            <div className="box">
              <span></span>
              <span></span>
              <span></span>
              <span></span>
              <div className="content">
                <img src={aii} alt="profile" width={250} height={250}/>
              </div>
              
            </div>
          </div>
        </div>
      </section>
      <h1 className="textdec">Projects...</h1>
    </div>
  </div>
)
  }

export default Header;
