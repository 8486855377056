import React from 'react'
import emailjs from 'emailjs-com'
import formimage from '../../assets/form.png'

function Footer() {
  function sendEmail(e){
    e.preventDefault(); 

    emailjs.sendForm('service_k1sdteb','template_qd2bpor',e.target,'user_SKJY7ziY5OzyAVwpy1jRs').then(res=>{
      console.log(res);
    }).catch(err=>console.log(err));
  }
	return (
		<div className="transparent text-gray-100 px-8 py-12">
			
      <div className="text-center w-full">
        
      </div>
      <div
        className="max-w-screen-xl mt-24 px-8 grid gap-8 grid-cols-1 md:grid-cols-2 md:px-12 lg:px-16 xl:px-32 py-16 mx-auto transparent text-gray-900 rounded-lg shadow-lg">
        <div className="flex flex-col justify-between">
          <div>
            <h2 className="text-2xl lg:text-3xl font-bold leading-tight text-gray-100">U can contact me here!</h2>
            <div class="text-gray-100 mt-8">
              Mail me Directly on : <a href="mailto:siddharthsharma7665@gmail.com" className="text-gray-100">siddharthsharma7665@gmail.com</a>
            </div>
          </div>
          <div className="mr-20">
          <img src={formimage} alt="formimage"/>
          </div>
         
        </div>
        <form onSubmit={sendEmail}>
          <div>
            <span className="uppercase text-sm text-gray-100 font-bold ">Full Name</span>
            <input className="w-full bg-gray-300 text-gray-900 mt-2 p-3 rounded-lg focus:outline-none focus:shadow-outline border-2 border-indigo-500  focus:border-fuchsia-400 focus:ring-1 focus:ring-fuchsia-600"
              type="text" name="name" placeholder=""/>
          </div>
          <div className="mt-8">
            <span className="uppercase text-sm text-gray-100 font-bold">Email</span>
            <input className="w-full bg-gray-300 text-gray-900 mt-2 p-3 rounded-lg focus:outline-none focus:shadow-outline border-2 border-indigo-500 focus:border-fuchsia-400 focus:ring-1 focus:ring-fuchsia-600"
              type="text" name="email"/>
          </div>
          <div className="mt-8">
            <span className="uppercase text-sm text-gray-100 font-bold">Message</span>
            <textarea name="message"
              className="w-full h-32 bg-gray-300 text-gray-900 mt-2 p-3 rounded-lg focus:outline-none focus:shadow-outline border-2 border-indigo-500 focus:border-fuchsia-400 focus:ring-1 focus:ring-fuchsia-600"></textarea>
          </div>
          <div className="mt-8">
            <button
              className="uppercase text-sm font-bold tracking-wide bg-indigo-500 text-gray-100 p-3 rounded-lg w-full focus:outline-none focus:shadow-outline focus:border-fuchsia-400 focus:ring-1 focus:ring-fuchsia-600">
              Send Message
            </button>
          </div>
          </form>
        </div>
      </div>
		
	)
}

export default Footer
