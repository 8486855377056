import React, { Component } from 'react';
import Progress from '../src/containers/Progress';
import './App.css';
import {  Footer,Header } from './containers';
import { Navbar } from './components';
import {ScrollToTop} from "../src/containers/ScrollToTop";
import Company from './containers/Companies/Company';
import Portfolio from './containers/Portfolio/Portfolio';
import Skills from './containers/skills/Skills';
import Certificates from './containers/cetificates/Certificates';
import Timeline from './containers/Timeline';

// import Progress from '../src/containers/Progress';
export default class App extends Component {
  state = {
    scrollPostion: 0
  }

  listenToScrollEvent = () => {
    document.addEventListener("scroll", () => {
      requestAnimationFrame(() => {
        this.calculateScrollDistance();
      });
    });
  }

  calculateScrollDistance = () => {
    const scrollTop = window.pageYOffset; // how much the user has scrolled by
    const winHeight = window.innerHeight;
    const docHeight = this.getDocHeight();

    const totalDocScrollLength = docHeight - winHeight;
    const scrollPostion = Math.floor(scrollTop / totalDocScrollLength * 100)

    this.setState({
      scrollPostion,
    });
  }

  getDocHeight = () => {
    return Math.max(
      document.body.scrollHeight, document.documentElement.scrollHeight,
      document.body.offsetHeight, document.documentElement.offsetHeight,
      document.body.clientHeight, document.documentElement.clientHeight
    );
  }

  componentDidMount() {
    this.listenToScrollEvent();
  }

  render() {
    return (
      <div className="App">
        <Progress scroll={this.state.scrollPostion + '%'} />
        <div className="gradient__bg">
    
          <Navbar />
          <Header />       
          <Company/>
          <Portfolio/>
          <Skills/>
          <Certificates/>
          <Timeline/>
          <Footer/>
          <ScrollToTop />
       </div>
      </div>
    );
  }
}