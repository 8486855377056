const cdata=[
    {
        "id":1,
        "name":"SISL Infotech system",
        "image":require("../../assets/sisl.jpeg"),
        "color":require("../../assets/red.jpg"),
        "title":"software developer",
        "year":"2020-03 to present",
        "desc":"1. Maintaning database with MS sql server\n2. ASp .Net core\n3. Audit the website \n4. Creating Reports ",
    },
    {
        "id":2,
        "name":"Velocis system",
        "image":require("../../assets/velocis.png"),
        "color":require("../../assets/sky.png"),
        "title":"software developer",
        "year":"2019-08 to 2020-02 ",
        "desc":"1. Maintaning database with MS sql server\n2. ASp .Net core\n3. Audit the website \n4. Creating Reports ",
    },
    {
        "id":3,
        "name":"Intellect Design",
        "image":require("../../assets/intellect.jpg"),
        "color":require("../../assets/yellow.png"),
        "title":"software Intern",
        "year":"2019-01 to 2019-06",
        "desc":"1. creating ocr with machine learning\n2. python \n3. tesseract ocr engine\n4. trained models ",
    },
    
]



export default cdata;