import React from "react";
import "./timeline.css";
import { Chrono } from "react-chrono";
import data from "./data";

export default function Timeline() {
  return (
    <div className="mainapp">
    <h1 className="text-gray-200 mt-10 text-2xl">Education</h1>
      <div className="timeline1">
      <Chrono enableOutline items={data} scrollable={false} mode="VERTICAL_ALTERNATING" slideShow>
          <div className="chrono-icons">
            <img
              src={require("../assets/vit.png")}
              alt="twitter"
            />
            <img
              src="https://img.icons8.com/ios-filled/100/000000/about.png"
              alt="twitter"
            />
            <img
              src="https://img.icons8.com/ios-filled/100/000000/contacts.png"
              alt="twitter"
            />
            <img
              src="https://img.icons8.com/ios-filled/100/000000/briefcase.png"
              alt="twitter"
            />
           
          </div>
        </Chrono>
      </div>
    </div>
  );
}
