import React from 'react'
import javascript from '../../assets/javascript.svg'
import python from '../../assets/python.svg'
import css from '../../assets/css.svg'
import database from '../../assets/database.svg'
import html from '../../assets/html.svg'
import net from '../../assets/dotnet.svg'
import tailwind from '../../assets/tailwind.svg'
import react from '../../assets/react.svg'
// import Slider from "react-slick";
// import "slick-carousel/slick/slick.css";
// import "slick-carousel/slick/slick-theme.css";

function Skills() {
  // var settings = {
  //   dots: false,
  //   infinite: true,
  //   autoplay: true,
  //   arrows: false,
  //   autoplaySpeed: 2000,
  //   slidesToShow: 8,
  //   slidesToScroll: 1
  // };
    return (    
      <>
      <h1 className="mt-10 flex justify-center align-center text-white text-2xl">Technologies</h1>
          <div className="lg:mt-10 flex items-stretch justify-evenly">
          {/* <Slider {...settings}> */}
          
            <img src={javascript} alt="javascript" className="w-40 h-40"/>
            
            <img src={python} alt="python" className="w-40 h-40"/>
            
            <img src={css} alt="css" className="w-40 h-40"/>
            
            <img src={database} alt="database" className="w-40 h-40"/>
             
            <img src={html} alt="html" className="w-40 h-40"/>
           
            <img src={net} alt="net" className="w-40 h-40"/>
           
            <img src={tailwind} alt="tailwind" className="w-40 h-40"/>
           
            <img src={react} alt="react" className="w-40 h-40"/>
          {/* </Slider> */}
          </div>
          </> 
        
    )
}

export default Skills
