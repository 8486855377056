const certifcateslist=[
    {
        "id":1,
        "image":require("../../assets/1.jpg")
    },

    {
        "id":2,
        "image":require("../../assets/2.jpg")
    },
    {
        "id":3,
        "image":require("../../assets/3.jpg")
    },
    {
        "id":4,
        "image":require("../../assets/4.jpg")
    },
    {
        "id":5,
        "image":require("../../assets/5.jpg")
    },
    {
        "id":6,
        "image":require("../../assets/6.jpg")
    },
    {
        "id":7,
        "image":require("../../assets/7.jpg")
    },
    {
        "id":8,
        "image":require("../../assets/8.jpg")
    },
    {
        "id":9,
        "image":require("../../assets/9.jpg")
    },
    {
        "id":10,
        "image":require("../../assets/10.jpg")
    },
    {
        "id":11,
        "image":require("../../assets/11.jpg")
    },
    {
        "id":12,
        "image":require("../../assets/12.jpg")
    },
    {
        "id":13,
        "image":require("../../assets/13.jpg")
    },
    {
        "id":14,
        "image":require("../../assets/14.jpg")
    },
    {
        "id":15,
        "image":require("../../assets/15.jpg")
    }
]


export default certifcateslist