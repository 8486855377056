import React from 'react'
import certifcateslist from './certdata'
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

function Certificates() {
    var settings = {
    dots: false,
    infinite: true,
    autoplay: true,
    arrows: false,
    autoplaySpeed: 2000,
    slidesToShow: 7,
    slidesToScroll: 1
  };
    return (
        <>
        <h1 className="mt-10 flex justify-center align-center text-white text-2xl">Certificates</h1>
        <div className="">
         <Slider {...settings}>
            {certifcateslist.map((cert,index)=>{
                return (
                    <div key={index} >
                    <img src={cert.image} alt="" className="w-40 h-40 mt-10 ml-10"/>
                    </div>
                )
            })}
            </Slider>
        </div>
        </>
    )
}

export default Certificates
