import React from 'react'
import './company.css'
import cdata from './companydata'


function Company() {
    return (
        <div className="lg:grid justify-items-stretch grid-flow-col sm:flex-col justify-around">
        {cdata.map((data,id)=>{
            return (
                <div className="card">
    <div className="banner"> 
    <img src={data.color} alt=""/>
    </div>
    <div className="wrapper" key={data.id}>
    <img src={data.image} className="image--cover" alt=""/>
    </div>
    <h2 className="name">{data.name}</h2>
    <div className="title">{data.title}</div>
    <div className="title">{data.year}</div>
    <div className="desc">{data.desc}</div>
</div>
                ) })}
          
        </div>
    )
}

export default Company
